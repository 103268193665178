@import '../../../styles/config/variables';
@import '../../../styles/mixins/layout-mixins';
@import '../../../styles/mixins/font-mixins';

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  @include laptop-bp() {
    flex-direction: row;
  }
}

.wordingSections {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 5%;
  margin: 40px 0 25px 0;
  @include laptop-bp() {
    width: 43%;
    margin: auto;
  }
}

.title {
  padding: 10px 0;
  @include heading1;
  font-size: 80px;
  font-weight: 400;
  color: $tp-rebuild-grey;
  @include tablet-bp() {
    font-size: 150px;
  }
  @include laptop-bp() {
    font-size: 20vw;
  }
}

.description {
  @include heading5;
  text-align: center;
  font-weight: 400;
  color: $tp-rebuild-lightest-grey;
  padding: 50px 0 0 0;
  @include laptop-bp() {
    @include heading4;
    padding: 10px 0;
    text-align: left;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: center;
  @include laptop-bp() {
    flex-direction: row;
    justify-content: space-between;
    margin: 32px 0;
  }
}

.imageSection {
  height: 34vh;
  position: relative;
  @include laptop-bp() {
    height: 100%;
    width: 57%;
  }
}

.mobileDescriptionButtons {
  display: flex;
  flex-direction: column;
  margin: auto;
  @include laptop-bp() {
    display: none;
  }
}

.image {
  object-fit: cover;
}
